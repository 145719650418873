<template>
  <TTView>
    <VRow>
      <VCol>
        <VBreadcrumbs
          class="pl-0"
          :items="breadcrumbs"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="12"
      >
        <h1 class="tt-text-headline-1">
          {{ step.name }}
        </h1>
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Описание
      </VCol>
      <VCol cols="9">
        {{ step.description }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Тип
      </VCol>
      <VCol cols="9">
        {{ step.type }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Уровень
      </VCol>
      <VCol cols="9">
        {{ step.defaultLevel }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Продолжительность
      </VCol>
      <VCol cols="9">
        {{ duration }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Параметры  JSON
      </VCol>
      <VCol cols="12">
        <VCard
          class="pa-6"
          color="tt-light-mono-4"
          elevation="0"
        >
          {{ step.parameters }}
        </VCard>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTBtn
          :to="{ name : Names.R_LEARNING_ASSESSMENT_STEP_EDIT }"
        >
          Редактировать шаг
        </TTBtn>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'StepView',
  inject: ['Names'],
  props: {
    accountId: { type: String, required: true },
    companyId: { type: String, required: true },
    groupId: { type: String, required: true },
    procedureId: { type: String, default: null },
    stepId: { type: String, default: null },
  },
  data() {
    return {
      account: {},
      company: {},
      group: {},
      procedure: {},
      step: {},
    };
  },
  computed: {
    breadcrumbs() {
      const breadcrumbs = [
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.account.name },
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.companyName },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS }, exact: true, text: 'Группы оценки' },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW }, exact: true, text: this.groupName },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_PROCEDURE_VIEW }, exact: true, text: this.procedureName },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_STEP_VIEW }, exact: true, text: this.stepName },
      ];

      return breadcrumbs;
    },
    companyName() {
      return this.company?.name ? this.company.name : `Компания: ${this.companyId}`;
    },
    groupName() {
      return this.group?.name ? this.group.name : '-';
    },
    procedureName() {
      return this.procedure?.name ? this.procedure.name : `Процедура: ${this.groupId}`;
    },
    stepName() {
      return this.step?.name ? this.step.name : `Шаг: ${this.procedure.id}`;
    },
    duration() {
      return this.step?.duration > 0 ? this.step.duration : '-';
    },
  },
  watch: {
    stepId: {
      handler: 'fetch', immediate: true,
    },
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;
        const assessmentApp = new this.$di.ddd.Assessment();
        const accountsApp = new this.$di.ddd.Accounts();

        const { groupId, companyId, accountId } = this;
        const [[account], [company], [group], [procedure], [step]] = await Promise.all([
          await accountsApp.services.account.getAccount({ id: accountId }),
          await accountsApp.services.account.getCompany({ id: companyId }),
          await assessmentApp.services.assessment.fetchGroup({ groupId }),
          await assessmentApp.services.assessment.getProcedure(
            { id: this.procedureId, groupId: this.groupId },
          ),
          await assessmentApp.services.assessment.getStep(
            { id: this.stepId, procedureId: this.procedureId },
          ),
        ]);

        this.account = account;
        this.company = { ...company };
        this.group = { ...group };
        this.procedure = { ...procedure };
        this.step = { ...step };
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    handleStepClick(item) {
      this.$router.push({
        name: this.Names.R_LEARNING_ASSESSMENT_STEP_VIEW,
        params: {
          stepId: item.id,
        },
      });
    },
  },
};
</script>
